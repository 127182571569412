<template>
  <div class="column" ref="tabLay">
    <transition
      name="fade"
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
    >
      <div v-show="showTopTab" class="tab_top">
        <div
          :class="current == 0 ? 'select m68' : 'normal m68'"
          @click="choiceClick(0)"
        >
          LGA课程
        </div>
        <div
          :class="current == 1 ? 'select m46' : 'normal m46'"
          @click="choiceClick(1)"
        >
          PES运动报告
        </div>
        <div
          :class="current == 2 ? 'select m50' : 'normal m50'"
          @click="choiceClick(2)"
        >
          KFA小小运动会
        </div>
        <div
          :class="current == 3 ? 'select m74' : 'normal m74'"
          @click="choiceClick(3)"
        >
          体育星计划
        </div>
        <div
          :class="current == 4 ? 'select m87' : 'normal m87'"
          @click="choiceClick(4)"
        >
          精英特训
        </div>
        <div
          :class="current == 5 ? 'select m66' : 'normal m66'"
          @click="choiceClick(5)"
        >
          TA计划
        </div>
        <div
          :class="current == 6 ? 'select m54' : 'normal m54'"
          @click="choiceClick(6)"
        >
          HHDD红花朵朵
        </div>
        <div
          :class="current == 7 ? 'select m210' : 'normal m210'"
          @click="choiceClick(7)"
        >
          PLUS会员
        </div>
        <div class="normal">首页</div>
      </div>
    </transition>
    <div class="top">
      <img class="logo" src="../../assets/img/home/home/Pic_LOGO_XYZQ.png" />
      <div class="max_width">
        <img class="text" src="../../assets/img/home/home/Pic_Text_ZBT.png" />
      </div>
      <div class="bottom">
        <div class="tab-tilte">LGA课程</div>
        <div class="tab-tilte">PES运动报告</div>
        <div class="tab-tilte">KFA小小运动会</div>
        <div class="tab-tilte">体育星计划</div>
        <div class="tab-tilte">精英特训</div>
        <div class="tab-tilte">TA计划</div>
        <div class="tab-tilte">HHDD红花朵朵</div>
        <div class="tab-tilte">PLUS会员</div>
        <div class="tab-tilte"></div>
      </div>
    </div>
    <div class="main_line"></div>
    <div id="tab_layout" class="tab_layout">
      <div
        class="tab_select"
        @mouseover="Mouseover(0)"
        @mouseleave="Mouseleave()"
        @click="choiceClick(0)"
      >
        <div v-if="current == 0 || mouthCurrent == 0" class="bg"></div>
        <img src="../../assets/img/home/home/Icon_NV_LGA.png" />
      </div>
      <div class="line"></div>
      <div
        class="tab_select"
        @mouseover="Mouseover(1)"
        @mouseleave="Mouseleave()"
        @click="choiceClick(1)"
      >
        <div v-if="current == 1 || mouthCurrent == 1" class="bg"></div>
        <img src="../../assets/img/home/home/Icon_NV_PES.png" />
      </div>
      <div class="line"></div>
      <div
        class="tab_select"
        @mouseover="Mouseover(2)"
        @mouseleave="Mouseleave()"
        @click="choiceClick(2)"
      >
        <div v-if="current == 2 || mouthCurrent == 2" class="bg"></div>
        <img src="../../assets/img/home/home/Icon_NV_KFA.png" />
      </div>
      <div class="line"></div>
      <div
        class="tab_select"
        @mouseover="Mouseover(3)"
        @mouseleave="Mouseleave()"
        @click="choiceClick(3)"
      >
        <div v-if="current == 3 || mouthCurrent == 3" class="bg"></div>
        <img src="../../assets/img/home/home/Icon_NV_XJH.png" />
      </div>
      <div class="line"></div>
      <div
        class="tab_select"
        @mouseover="Mouseover(4)"
        @mouseleave="Mouseleave()"
        @click="choiceClick(4)"
      >
        <div v-if="current == 4 || mouthCurrent == 4" class="bg"></div>
        <img src="../../assets/img/home/home/Icon_NV_JYTX.png" />
      </div>
      <div class="line"></div>
      <div
        class="tab_select"
        @mouseover="Mouseover(5)"
        @mouseleave="Mouseleave()"
        @click="choiceClick(5)"
      >
        <div v-if="current == 5 || mouthCurrent == 5" class="bg"></div>
        <img src="../../assets/img/home/home/Icon_NV_TJH.png" />
      </div>
      <div class="line"></div>
      <div
        class="tab_select"
        @mouseover="Mouseover(6)"
        @mouseleave="Mouseleave()"
        @click="choiceClick(6)"
      >
        <div v-if="current == 6 || mouthCurrent == 6" class="bg"></div>
        <img src="../../assets/img/home/home/Icon_NV_HHDD.png" />
      </div>
      <div class="line"></div>
      <div
        class="tab_select"
        @mouseover="Mouseover(7)"
        @mouseleave="Mouseleave()"
        @click="choiceClick(7)"
      >
        <div v-if="current == 7 || mouthCurrent == 7" class="bg"></div>
        <img src="../../assets/img/home/home/Icon_NV_PLUS.png" />
      </div>
      <div class="line"></div>
      <div
        class="tab_select"
        @mouseover="Mouseover(8)"
        @mouseleave="Mouseleave()"
        @click="choiceClick(8)"
      >
        <div v-if="current == 8 || mouthCurrent == 8" class="bg"></div>
        <img src="../../assets/img/home/home/Icon_NV_XYZX.png" />
      </div>
      <div class="line"></div>
    </div>
    <div class="main_line"></div>
    <div class="column_l">
      <transition>
        <div
          class="sfy_l animate__animated animate__fadeIn"
          :style="{ transform: 'translateY(' + remPo + 'px)' }"
        >
          <img
            v-if="swiperIndex == 1 || swiperIndex == 4"
            src="../../assets/img/home/home/Pic_Text_ZTY_Down.png"
          />
          <img
            v-else-if="swiperIndex == 2"
            src="../../assets/img/home/home/Pic_Text_GYY_Down.png"
          />
          <img v-else src="../../assets/img/home/home/Pic_Text_SFY_Down.png" />
        </div>
      </transition>

      <div class="image_l">
        <div class="swiper">
          <swiper
            :options="swiperOption"
            ref="mySwiper"
            @slideChange="slideChange"
          >
            <swiper-slide>
              <div class="swiper-box">
                <img src="../../assets/img/home/home/Pic_GYY_Girl.png" />
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="swiper-box">
                <img src="../../assets/img/home/home/Pic_GYY_Girl1.png" />
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="swiper-box">
                <img src="../../assets/img/home/home/Pic_SFY_Boy.png" />
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
      <transition>
        <div
          class="sfy_l sfy_index"
          id="sfy_l"
          :style="{ transform: 'translateY(' + remPo + 'px)' }"
        >
          <img
            v-if="swiperIndex == 1 || swiperIndex == 4"
            class="animate__animated animate__fadeIn"
            src="../../assets/img/home/home/Pic_Text_ZTY.png"
          />
          <img
            v-else-if="swiperIndex == 2"
            class="animate__animated animate__fadeIn"
            src="../../assets/img/home/home/Pic_Text_GYY_Up.png"
          />
          <img
            v-else
            class="animate__animated animate__fadeIn"
            src="../../assets/img/home/home/Pic_Text_SFY_Up.png"
          />
          <div class="row">
            <div
              v-if="swiperIndex == 1 || swiperIndex == 4"
              class="select animate__animated animate__fadeIn"
              style="cursor: pointer;"
            >
              足特园
            </div>
            <div v-else class="normal" @click="clickTab(1)" style="cursor: pointer;">足特园</div>
            <div
              v-if="swiperIndex == 2"
              class="select m7 animate__animated animate__fadeIn"
              style="cursor: pointer;"
            >
              公益园
            </div>
            <div v-else class="normal m7" @click="clickTab(2)" style="cursor: pointer;">公益园</div>
            <div
              v-if="swiperIndex == 3"
              class="select m7 animate__animated animate__fadeIn"
              style="cursor: pointer;"
            >
              示范园
            </div>
            <div v-else class="normal m7" @click="clickTab(3)" style="cursor: pointer;">示范园</div>
          </div>
        </div>
      </transition>
    </div>
    <div id="anim14" class="row_center">
      <transition
        name="fade"
        enter-active-class="animate__animated animate__fadeInUp"
        leave-active-class="animate__animated animate__fadeOutDown"
      >
        <img
          v-show="anim14"
          src="../../assets/img/home/home/Pic_Text_PEFA.png"
        />
      </transition>
    </div>
    <div id="anim1" class="m35 row_center">
      <transition
        name="fade"
        enter-active-class="animate__animated animate__fadeInUp"
        leave-active-class="animate__animated animate__fadeOutDown"
      >
        <div v-show="anim1" class="mk_text">
          <span
            >全国足球特色幼儿园全国足球特色幼儿园全国足球特色幼儿园全国足球特色幼儿园</span
          >
          <span
            >全国足球特色幼儿园全国足球特色幼儿园全国足球特色幼儿园全国足球特色幼儿园</span
          >
        </div>
      </transition>
    </div>
    <div class="girl_l" id="girl_scroll">
      <div class="image_l">
        <img class="image" src="../../assets/img/home/home/Pic_IONE_Girl.png" />
      </div>
      <div
        class="girl_scroll"
        :style="{ transform: 'translateY(' + remPo2 + 'px)' }"
      >
        <img src="../../assets/img/home/home/Pic_IONE_PT.png" />
        <img class="m31" src="../../assets/img/home/home/Pic_Text_IONE.png" />
      </div>
    </div>
    <div id="anim15" class="m35 row_center">
      <transition
        name="fade"
        enter-active-class="animate__animated animate__fadeInUp"
        leave-active-class="animate__animated animate__fadeOutDown"
      >
        <img
          v-show="anim15"
          src="../../assets/img/home/home/Pic_Text_IONE_Title.png"
        />
      </transition>
    </div>
    <div id="anim2" class="m35 row_center">
      <transition
        name="fade"
        enter-active-class="animate__animated animate__fadeInUp"
        leave-active-class="animate__animated animate__fadeOutDown"
      >
        <div v-show="anim2" class="mk_text">
          <span
            >全国足球特色幼儿园全国足球特色幼儿园全国足球特色幼儿园全国足球特色幼儿园</span
          >
          <span
            >全国足球特色幼儿园全国足球特色幼儿园全国足球特色幼儿园全国足球特色幼儿园</span
          >
        </div>
      </transition>
    </div>
    <div class="pda_test_l" id="pda_test">
      <div class="image1" :style="{ top: remPo3 + 'rem' }">
        <img src="../../assets/img/home/home/Pic_PDA_DOWN.png" />
      </div>
      <div class="image_l">
        <img class="image" src="../../assets/img/home/home/Pic_PDA_Boy.png" />
      </div>
      <div class="image2" :style="{ top: remPo4 + 'rem' }">
        <img src="../../assets/img/home/home/Pic_Text_PDA.png" />
      </div>
    </div>
    <div class="m21 row_center">
      <img src="../../assets/img/home/home/Pic_PDA_ICONs.png" />
    </div>
    <div id="anim16" class="row_center">
      <transition
        name="fade"
        enter-active-class="animate__animated animate__fadeInUp"
        leave-active-class="animate__animated animate__fadeOutDown"
      >
        <img
          v-show="anim16"
          src="../../assets/img/home/home/Pic_Text_PDA_Title.png"
        />
      </transition>
    </div>
    <div id="anim4" class="m28 row_center">
      <transition
        name="fade"
        enter-active-class="animate__animated animate__fadeInUp"
        leave-active-class="animate__animated animate__fadeOutDown"
      >
        <div v-show="anim4" class="mk_text">
          <span
            >全国足球特色幼儿园全国足球特色幼儿园全国足球特色幼儿园全国足球特色幼儿园</span
          >
          <span
            >全国足球特色幼儿园全国足球特色幼儿园全国足球特色幼儿园全国足球特色幼儿园</span
          >
        </div>
      </transition>
    </div>
    <div class="tcp_l" id="anim5">
      <div class="image2" :style="{ top: remPo5 + 'rem' }">
        <img src="../../assets/img/home/home/Pic_Text_TCP_Down.png" />
      </div>
      <div class="image1">
        <img class="image" src="../../assets/img/home/home/Pic_SZPX.png" />
      </div>

      <div class="image2" :style="{ top: remPo5 + 'rem' }">
        <img src="../../assets/img/home/home/Text_SZPX_W.png" />
      </div>
    </div>
    <div id="anim17" class="m22 row_center">
      <transition
        name="fade"
        enter-active-class="animate__animated animate__fadeInUp"
        leave-active-class="animate__animated animate__fadeOutDown"
      >
        <img
          v-show="anim17"
          src="../../assets/img/home/home/Pic_Text_TCP_Title.png"
        />
      </transition>
    </div>
    <div id="anim6" class="m35 row_center">
      <transition
        name="fade"
        enter-active-class="animate__animated animate__fadeInUp"
        leave-active-class="animate__animated animate__fadeOutDown"
      >
        <div v-show="anim6" class="mk_text">
          <span
            >全国足球特色幼儿园全国足球特色幼儿园全国足球特色幼儿园全国足球特色幼儿园</span
          >
          <span
            >全国足球特色幼儿园全国足球特色幼儿园全国足球特色幼儿园全国足球特色幼儿园</span
          >
        </div>
      </transition>
    </div>
    <div class="xj_sd_l">
      <div class="image1">
        <img class="image" src="../../assets/img/home/home/Pic_PT_XCFT.png" />
      </div>
      <div class="image2">
        <img class="image" src="../../assets/img/home/home/Pic_Text_XCFT.png" />
      </div>
    </div>
    <div id="anim7" class="m28 row_center">
      <transition
        name="fade"
        enter-active-class="animate__animated animate__fadeInUp"
        leave-active-class="animate__animated animate__fadeOutDown"
      >
        <img
          v-show="anim7"
          src="../../assets/img/home/home/Pic_Text_ZCFT_Title.png"
        />
      </transition>
    </div>
    <div class="m28 row_center">
      <transition
        name="fade"
        enter-active-class="animate__animated animate__fadeInUp"
        leave-active-class="animate__animated animate__fadeOutDown"
      >
        <div v-show="anim7" class="mk_text">
          <span
            >全国足球特色幼儿园全国足球特色幼儿园全国足球特色幼儿园全国足球特色幼儿园</span
          >
          <span
            >全国足球特色幼儿园全国足球特色幼儿园全国足球特色幼儿园全国足球特色幼儿园</span
          >
        </div>
      </transition>
    </div>
    <div class="xx_l" id="anim8">
      <div class="image2">
        <img class="image" src="../../assets/img/home/home/Pic_LT_B.png" />
      </div>
      <transition
        name="fade"
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="animate__animated animate__fadeOut"
      >
        <div v-show="!anim8" class="image1">
          <img class="image" src="../../assets/img/home/home/Pic_LT_A.png" />
        </div>
      </transition>

      <div class="image5">
        <img class="image" src="../../assets/img/home/home/Pic_LT_E.png" />
      </div>
      <transition
        name="fade"
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="animate__animated animate__fadeOut"
      >
        <div v-show="!anim8" class="image3">
          <img class="image" src="../../assets/img/home/home/Pic_LT_C.png" />
        </div>
      </transition>
      <transition
        name="fade"
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="animate__animated animate__fadeOut"
      >
        <div v-show="!anim8" class="image4">
          <img class="image" src="../../assets/img/home/home/Pic_LT_D.png" />
        </div>
      </transition>
      <transition
        name="fade"
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="animate__animated animate__fadeOut"
      >
        <div v-show="!anim8" class="image7">
          <img class="image" src="../../assets/img/home/home/Pic_LT_F.png" />
        </div>
      </transition>

      <div class="image6">
        <img class="image" src="../../assets/img/home/home/Pic_LT.png" />
      </div>
      <transition
        name="fade"
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="animate__animated animate__fadeOut"
      >
        <div v-show="!anim8" class="image8">
          <img class="image" src="../../assets/img/home/home/Pic_LT_G.png" />
        </div>
      </transition>

      <div class="image10">
        <img class="image" src="../../assets/img/home/home/Pic_LT_I.png" />
      </div>
      <div class="image9">
        <img class="image" src="../../assets/img/home/home/Pic_LT_H.png" />
      </div>
    </div>
    <div id="anim9" class="m38 row_center">
      <transition
        name="fade"
        enter-active-class="animate__animated animate__fadeInUp"
        leave-active-class="animate__animated animate__fadeOutDown"
      >
        <img
          v-show="anim9"
          src="../../assets/img/home/home/Pic_Text_FORUM_Title.png"
        />
      </transition>
    </div>
    <div class="m34 row_center">
      <transition
        name="fade"
        enter-active-class="animate__animated animate__fadeInUp"
        leave-active-class="animate__animated animate__fadeOutDown"
      >
        <div v-show="anim9" class="mk_text">
          <span
            >全国足球特色幼儿园全国足球特色幼儿园全国足球特色幼儿园全国足球特色幼儿园</span
          >
          <span
            >全国足球特色幼儿园全国足球特色幼儿园全国足球特色幼儿园全国足球特色幼儿园</span
          >
        </div>
      </transition>
    </div>

    <div class="ddz_l">
      <div id="anim10" class="column1">
        <transition
          name="fade"
          enter-active-class="animate__animated animate__fadeInUp"
          leave-active-class="animate__animated animate__fadeOutDown"
        >
          <img
            v-show="anim10"
            class="image"
            src="../../assets/img/home/home/Pic_Text_DDZ_Title.png"
          />
        </transition>
        <transition
          name="fade"
          enter-active-class="animate__animated animate__fadeInUp"
          leave-active-class="animate__animated animate__fadeOutDown"
        >
          <div v-show="anim13" class="text">
            由各省、自治区、直辖市教育厅 （教委）新疆生产建设兵团教育
            局组成，对辖区内园所进行信息 化管理，统筹和协助园所进行教
            学管理、申报管理和进度督办等 幼儿足球相关工作。
          </div>
        </transition>
      </div>
      <div class="column2">
        <img
          id="scroll_image"
          class="swip_image"
          src="../../assets/img/home/home/Pic_DDZ_PT.png"
          :style="{
            opacity: opacity1,
            transform: 'scale(' + scale1 + ')',
          }"
        />
        <img
          id="scroll_image1"
          class="swip_image1"
          src="../../assets/img/home/home/Pic_DDZ_PT.png"
          :style="{
            opacity: opacity2,
            transform: 'scale(' + scale2 + ')',
          }"
        />
        <img
          id="scroll_image2"
          class="swip_image1"
          src="../../assets/img/home/home/Pic_DDZ_PT.png"
          :style="{
            opacity: opacity3,
            transform: 'scale(' + scale3 + ')',
          }"
        />
        <img
          id="scroll_image3"
          class="swip_image1"
          src="../../assets/img/home/home/Pic_DDZ_PT.png"
        />
      </div>
    </div>
    <div class="mt180 row_center">
      <img src="../../assets/img/home/home/Pic_ZHYEY_PT.png" />
    </div>
    <div id="anim11">
      <transition
        name="fade"
        enter-active-class="animate__animated animate__fadeInUp"
        leave-active-class="animate__animated animate__fadeOutDown"
      >
        <div v-show="anim11" class="zh_lay">
          <div class="image_l">
            <img
              class="image"
              src="../../assets/img/home/home/Pic_LOGO_XYZQ_Title.png"
            />
          </div>
          <div class="tab_l">
            <div class="w354"></div>
            <div class="flex">
              <!-- <div class="tab">设置管理</div> -->
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div id="anim12" class="m30 row_center">
      <transition
        name="fade"
        enter-active-class="animate__animated animate__fadeInUp"
        leave-active-class="animate__animated animate__fadeOutDown"
      >
        <div v-show="anim12" class="mk_text">
          <span
            >全国足球特色幼儿园全国足球特色幼儿园全国足球特色幼儿园全国足球特色幼儿园</span
          >
          <span
            >全国足球特色幼儿园全国足球特色幼儿园全国足球特色幼儿园全国足球特色幼儿园</span
          >
        </div>
      </transition>
    </div>
    <div class="m160 row_center">
      <img src="../../assets/img/home/home/Pic_LOGO_XYZQ_NEWS.png" />
    </div>
    <div id="pic1" class="m19 pic_l" ref="pic1" @mouseleave="picleave()">
      <img
        :style="'transform: translateX(' + -translateNum + 'px)'"
        src="../../assets/img/home/home/Pic_Bottom_Pic.png"
      />
      <img
        :style="'transform: translateX(' + -translateNum1 + 'px)'"
        src="../../assets/img/home/home/Pic_Bottom_Pic.png"
      />
    </div>

    <div id="pic2" class="m12 pic_l" @mouseleave="picleave()">
      <img
        :style="'transform: translateX(' + -translateNum2 + 'px)'"
        src="../../assets/img/home/home/Pic_Bottom_Pic1.png"
      />
      <img
        :style="'transform: translateX(' + -translateNum3 + 'px)'"
        src="../../assets/img/home/home/Pic_Bottom_Pic1.png"
      />
    </div>
    <div id="pic3" class="m12 pic_l" @mouseleave="picleave()">
      <img
        :style="'transform: translateX(' + -translateNum4 + 'px)'"
        src="../../assets/img/home/home/Pic_Bottom_Pic2.png"
      />
      <img
        :style="'transform: translateX(' + -translateNum5 + 'px)'"
        src="../../assets/img/home/home/Pic_Bottom_Pic2.png"
      />
    </div>
    <div class="h211"></div>
  </div>
</template>
<script>
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
export default {
  name: "home",
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    return {
      current: -1,
      mouthCurrent: -1,
      showTopTab: false,
      remPo: 0,
      preSfyY: 0,
      Y1: 0,
      ratio: 0.3,
      anim1: false,
      remPo2: 0,
      anim2: false,
      remPo3: 1.46,
      remPo4: 4.55,
      remPo5: 4.24,
      anim3: false,
      anim4: false,
      anim5: false,
      anim6: false,
      anim7: false,
      anim8: true,
      anim9: true,
      anim10: false,
      anim11: false,
      anim12: false,
      anim13: false,
      anim14: false,
      anim15: false,
      anim16: false,
      anim17: false,
      domObj: null,
      translateNum: 0,
      translateNum1: 0,
      timer: null,
      timer1: null,
      domObj1: null,
      translateNum2: 0,
      translateNum3: 0,
      timer2: null,
      timer3: null,
      domObj2: null,
      translateNum4: 0,
      translateNum5: 0,
      timer4: null,
      timer5: null,
      opacity1: 1.0,
      scale1: 1,
      opacity2: 1.0,
      scale2: 1,
      opacity3: 1.0,
      scale3: 1,
      ratio1: 0.4,
      ratio2: 0.2,
      preScroll: 0,
      swiperOption: {
        loop: true,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        speed: 3000,
      },
      swiperIndex: 1,
    };
  },

  methods: {
    choiceClick(index) {
      if (this.$store.state.userInfo.token) {
        this.current = index;
        if (index == 0) {
          this.$router.push({
            path: "/LGAHome",
          });
        } else if (index == 1) {
          this.$router.push({
            path: "/PESHome",
          });
        } else if (index == 8) {
          this.$router.push({
            path: "/school/home",
          });
        }
      } else {
        this.$store.commit("setLoginState", "login");
      }
    },
    Mouseover(index) {
      this.mouthCurrent = index;
    },
    Mouseleave() {
      this.mouthCurrent = -1;
    },
    slideChange() {
      this.swiperIndex = this.$refs.mySwiper.swiper.activeIndex;
    },
    clickTab(index) {
      this.swiperIndex = index;
      this.$refs.mySwiper.swiper.activeIndex = index;
    },
    handleScroll() {
      var scrollTop =
        document.body.scrollTop || document.documentElement.scrollTop;

      let tab_layout = document.getElementById("tab_layout");
      let tab_layoutY = tab_layout.offsetTop;
      let tab_layoutH = tab_layout.offsetHeight;
      if (scrollTop >= tab_layoutY + tab_layoutH) {
        if (scrollTop - this.preScroll < 0) {
          this.showTopTab = true;
        } else {
          this.showTopTab = false;
        }
      } else {
        this.showTopTab = false;
      }

      // VK示范课程动画效果

      var positionY1 = (scrollTop - this.preScroll) / 3;
      this.remPo = this.remPo - positionY1;
      if (this.remPo >= 0) {
        this.remPo = 0;
      } else if (this.remPo <= -300) {
        this.remPo = -300;
      }

      var height = scrollTop + window.innerHeight;
      let anim1 = document.getElementById("anim1");
      let animY1 = anim1.offsetTop;
      if (height >= animY1 + 20) {
        this.anim1 = true;
      } else {
        this.anim1 = false;
      }
      //幼儿足球公益项目动画
      let anim14 = document.getElementById("anim14");
      let animY14 = anim14.offsetTop;
      if (height >= animY14 + 20) {
        this.anim14 = true;
      } else {
        this.anim14 = false;
      }
      // IONE课程动画效果
      let girl_scroll = document.getElementById("girl_scroll");
      let Y2 = girl_scroll.offsetTop;
      let H2 = girl_scroll.offsetHeight;
      if (height >= Y2 + H2 / 2) {
        var positionY2 = (scrollTop - this.preScroll) / 3;
        this.remPo2 = this.remPo2 - positionY2;
      }
      console.log(this.remPo2);
      if (this.remPo2 <= -250) {
        this.remPo2 = -250;
      } else if (this.remPo2 >= 0) {
        this.remPo2 = 0;
      }
      this.preScroll = scrollTop;
      let anim15 = document.getElementById("anim15");
      let animY15 = anim15.offsetTop;
      if (height >= animY15 + 20) {
        this.anim15 = true;
      } else {
        this.anim15 = false;
      }

      let anim2 = document.getElementById("anim2");
      let animY2 = anim2.offsetTop;
      if (height >= animY2 + 20) {
        this.anim2 = true;
      } else {
        this.anim2 = false;
      }
      //PDA测试体系动画
      let pda_test = document.getElementById("pda_test");
      let Y3 = pda_test.offsetTop;
      let H3 = pda_test.offsetHeight;
      if (height >= Y3 + H3 / 2) {
        var positionY3 = (height - Y3 - H3 / 2) * this.ratio2;
        this.remPo3 = 1.46 - positionY3 / 100;
        this.remPo4 = 4.55 - positionY3 / 100;
      }
      if (this.remPo3 <= 0) {
        this.remPo3 = 0;
      } else if (this.remPo3 >= 1.46) {
        this.remPo3 = 1.46;
      }
      if (this.remPo4 <= 0) {
        this.remPo4 = 0;
      } else if (this.remPo4 >= 4.55) {
        this.remPo4 = 4.55;
      }

      let anim16 = document.getElementById("anim16");
      let animY16 = anim16.offsetTop;
      if (height >= animY16 + 20) {
        this.anim16 = true;
      } else {
        this.anim16 = false;
      }

      let anim4 = document.getElementById("anim4");
      let animY4 = anim4.offsetTop;
      if (height >= animY4 + 20) {
        this.anim4 = true;
      } else {
        this.anim4 = false;
      }
      //TCP培训体系动画
      let anim5 = document.getElementById("anim5");
      let animY5 = anim5.offsetTop;
      let animH5 = anim5.offsetHeight;
      if (height >= animY5 + animH5 / 2) {
        var positionY5 = (height - animY5 - animH5 / 2) * this.ratio;
        this.remPo5 = 4.24 - positionY5 / 100;
      }
      if (this.remPo5 < 0) {
        this.remPo5 = 0;
      } else if (this.remPo5 >= 4.24) {
        this.remPo5 = 4.24;
      }

      let anim6 = document.getElementById("anim6");
      let animY6 = anim6.offsetTop;
      if (height < animY6 + 20) {
        this.anim6 = false;
      } else {
        this.anim6 = true;
      }

      let anim17 = document.getElementById("anim17");
      let animY17 = anim17.offsetTop;
      if (height >= animY17 + 20) {
        this.anim17 = true;
      } else {
        this.anim17 = false;
      }
      // 新疆试点动画
      let anim7 = document.getElementById("anim7");
      let animY7 = anim7.offsetTop;
      if (height >= animY7 + 20) {
        this.anim7 = true;
      } else {
        this.anim7 = false;
      }
      //督导组动画
      let scroll_image = document.getElementById("scroll_image");
      let scroll_imageH = scroll_image.offsetTop;
      let scroll_image1 = document.getElementById("scroll_image1");
      let scroll_image1H = scroll_image1.offsetTop;
      let scroll_image2 = document.getElementById("scroll_image2");
      let scroll_image2H = scroll_image2.offsetTop;
      let scroll_image3 = document.getElementById("scroll_image3");
      let scroll_image3H = scroll_image3.offsetTop;
      let imgH = scroll_image1.offsetHeight;
      var value1 = scroll_image1H - scroll_imageH;
      var value2 = scroll_image2H - scroll_image1H;
      var value3 = scroll_image3H - scroll_image2H;

      this.opacity1 = value1 / imgH;
      this.scale1 = 0.9 + value1 / imgH / 10;
      this.opacity2 = value2 / imgH;
      this.scale2 = 0.9 + value2 / imgH / 10;
      this.opacity3 = value3 / imgH;
      this.scale3 = 0.9 + value3 / imgH / 10;
      // 足球讯息动画
      let anim8 = document.getElementById("anim8");
      let animY8 = anim8.offsetTop;
      if (scrollTop >= animY8 + 100) {
        this.anim8 = true;
      } else {
        this.anim8 = false;
      }
      let anim9 = document.getElementById("anim9");
      let animY9 = anim9.offsetTop;
      if (height >= animY9 + 20) {
        this.anim9 = true;
      } else {
        this.anim9 = false;
      }
      let anim10 = document.getElementById("anim10");
      let animY10 = anim10.offsetTop;
      if (height >= animY10 + 20) {
        this.anim10 = true;
      } else {
        this.anim10 = false;
      }
      if (height >= animY10 + 210) {
        this.anim13 = true;
      } else {
        this.anim13 = false;
      }
      let anim11 = document.getElementById("anim11");
      let animY11 = anim11.offsetTop;
      if (height >= animY11 + 20) {
        this.anim11 = true;
      } else {
        this.anim11 = false;
      }
      let anim12 = document.getElementById("anim12");
      let animY12 = anim12.offsetTop;
      if (height >= animY12 + 20) {
        this.anim12 = true;
      } else {
        this.anim12 = false;
      }
    },
    scrollFunction() {
      this.domObj = document.getElementById("pic1"); // 通过id获取要设置的div
      if (this.domObj.attachEvent) {
        // IE
        this.domObj.attachEvent("onmousewheel", this.mouseScroll);
      } else if (this.domObj.addEventListener) {
        this.domObj.addEventListener("DOMMouseScroll", this.mouseScroll, false);
      }
      this.domObj.onmousewheel = this.domObj.onmousewheel = this.mouseScroll;
      this.domObj1 = document.getElementById("pic2"); // 通过id获取要设置的div
      if (this.domObj1.attachEvent) {
        // IE
        this.domObj1.attachEvent("onmousewheel", this.mouseScroll1);
      } else if (this.domObj1.addEventListener) {
        this.domObj1.addEventListener(
          "DOMMouseScroll",
          this.mouseScroll1,
          false
        );
      }
      this.domObj1.onmousewheel = this.domObj1.onmousewheel = this.mouseScroll1;
      this.domObj2 = document.getElementById("pic3"); // 通过id获取要设置的div
      if (this.domObj2.attachEvent) {
        // IE
        this.domObj2.attachEvent("onmousewheel", this.mouseScroll2);
      } else if (this.domObj2.addEventListener) {
        this.domObj2.addEventListener(
          "DOMMouseScroll",
          this.mouseScroll2,
          false
        );
      }
      this.domObj2.onmousewheel = this.domObj2.onmousewheel = this.mouseScroll2;
    },
    mouseScroll(event) {
      // google 浏览器下
      let detail = event.wheelDelta || event.detail;
      let moveForwardStep = -1;
      let moveBackStep = 1;
      let step = detail > 0 ? moveForwardStep * 100 : moveBackStep * 100;

      event.preventDefault(); // 阻止浏览器默认事件

      if (step > 0) {
        if (this.timer1 == null) {
          if (this.timer == null) {
            this.roll();
          }
        } else {
          window.clearTimeout(this.timer1);
          this.timer1 = null;
          this.roll();
        }
      } else {
        if (this.timer == null) {
          if (this.timer1 == null) {
            this.roll1();
          }
        } else {
          window.clearTimeout(this.timer);
          this.timer = null;
          this.roll1();
        }
      }
    },
    // 向左滑动
    roll() {
      this.timer = setInterval(() => {
        // 循环给每一个对象修改translateNum属性值，从而动态修改页面元素的transform样式，达到滚动的效果
        if (1920 - this.translateNum <= 0) {
          window.clearTimeout(this.timer);
          this.timer = null;
        } else {
          this.translateNum = this.translateNum + 1;
          this.translateNum1 = this.translateNum1 + 1;
        }
      }, 30);
    },
    // 向右滑动
    roll1() {
      this.timer1 = setInterval(() => {
        // 循环给每一个对象修改translateNum属性值，从而动态修改页面元素的transform样式，达到滚动的效果

        if (this.translateNum <= 0) {
          window.clearTimeout(this.timer1);
          this.timer1 = null;
        } else {
          this.translateNum = this.translateNum - 1;
          this.translateNum1 = this.translateNum1 - 1;
        }
      }, 30);
    },
    mouseScroll1(event) {
      // google 浏览器下
      let detail = event.wheelDelta || event.detail;
      let moveForwardStep = -1;
      let moveBackStep = 1;
      let step = detail > 0 ? moveForwardStep * 100 : moveBackStep * 100;

      event.preventDefault(); // 阻止浏览器默认事件

      if (step > 0) {
        if (this.timer3 == null) {
          if (this.timer2 == null) {
            this.roll2();
          }
        } else {
          window.clearTimeout(this.timer3);
          this.timer3 = null;
          this.roll2();
        }
      } else {
        if (this.timer2 == null) {
          if (this.timer3 == null) {
            this.roll3();
          }
        } else {
          window.clearTimeout(this.timer2);
          this.timer2 = null;
          this.roll3();
        }
      }
    },
    // 向左滑动
    roll2() {
      this.timer2 = setInterval(() => {
        // 循环给每一个对象修改translateNum属性值，从而动态修改页面元素的transform样式，达到滚动的效果
        if (1920 - this.translateNum2 <= 0) {
          window.clearTimeout(this.timer2);
          this.timer2 = null;
        } else {
          this.translateNum2 = this.translateNum2 + 1;
          this.translateNum3 = this.translateNum3 + 1;
        }
      }, 30);
    },
    // 向右滑动
    roll3() {
      this.timer3 = setInterval(() => {
        // 循环给每一个对象修改translateNum属性值，从而动态修改页面元素的transform样式，达到滚动的效果

        if (this.translateNum2 <= 0) {
          window.clearTimeout(this.timer3);
          this.timer3 = null;
        } else {
          this.translateNum2 = this.translateNum2 - 1;
          this.translateNum3 = this.translateNum3 - 1;
        }
      }, 30);
    },
    mouseScroll2(event) {
      // google 浏览器下
      let detail = event.wheelDelta || event.detail;
      let moveForwardStep = -1;
      let moveBackStep = 1;
      let step = detail > 0 ? moveForwardStep * 100 : moveBackStep * 100;

      event.preventDefault(); // 阻止浏览器默认事件

      if (step > 0) {
        if (this.timer5 == null) {
          if (this.timer4 == null) {
            this.roll4();
          }
        } else {
          window.clearTimeout(this.timer5);
          this.timer5 = null;
          this.roll4();
        }
      } else {
        if (this.timer4 == null) {
          if (this.timer5 == null) {
            this.roll5();
          }
        } else {
          window.clearTimeout(this.timer4);
          this.timer4 = null;
          this.roll5();
        }
      }
    },
    // 向左滑动
    roll4() {
      this.timer4 = setInterval(() => {
        // 循环给每一个对象修改translateNum属性值，从而动态修改页面元素的transform样式，达到滚动的效果
        if (1920 - this.translateNum4 <= 0) {
          window.clearTimeout(this.timer4);
          this.timer4 = null;
        } else {
          this.translateNum4 = this.translateNum4 + 1;
          this.translateNum5 = this.translateNum5 + 1;
        }
      }, 30);
    },
    // 向右滑动
    roll5() {
      this.timer5 = setInterval(() => {
        // 循环给每一个对象修改translateNum属性值，从而动态修改页面元素的transform样式，达到滚动的效果

        if (this.translateNum4 <= 0) {
          window.clearTimeout(this.timer5);
          this.timer5 = null;
        } else {
          this.translateNum4 = this.translateNum4 - 1;
          this.translateNum5 = this.translateNum5 - 1;
        }
      }, 30);
    },
    picleave() {
      window.clearTimeout(this.timer);
      this.timer = null;
      window.clearTimeout(this.timer1);
      this.timer1 = null;
      window.clearTimeout(this.timer2);
      this.timer2 = null;
      window.clearTimeout(this.timer3);
      this.timer3 = null;
      window.clearTimeout(this.timer4);
      this.timer4 = null;
      window.clearTimeout(this.timer5);
      this.timer5 = null;
    },
  },
  beforeDestroy() {
    // 销毁定时器，否则可能导致重载此组件时会有多个定时器同时执行，使得滚动变快
    window.clearTimeout(this.timer);
    window.clearTimeout(this.timer1);
  },
  mounted() {
    let _this = this;
    let sfy_l = document.getElementById("sfy_l");
    this.Y1 = sfy_l.offsetTop;
    window.onscroll = function () {
      _this.handleScroll();
    };
    this.scrollFunction();
  },
  computed: {},
};
</script>
<style lang="scss" scoped>
.column {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #e7e7e7;
}
.top {
  height: 150px;
  background: #6a0203;
  position: relative;
  left: 0px;
  top: 0px;
  width: 100%;
  .logo {
    position: relative;
    margin-left: 64px;
    margin-top: 25px;
  }
  .max_width {
    position: absolute;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0px;
    top: 0px;
  }
  .text {
    margin-top: 53px;
  }
  .bottom {
    position: absolute;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0px;
    bottom: 0px;
    .tab-tilte {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      line-height: 12px;
      width: 152px;
      margin-bottom: 5px;
      text-align: center;
    }
  }
}
.main_line {
  width: 100%;
  height: 1px;
  background: #2e0000;
}
.tab_layout {
  height: 128px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .tab_select {
    width: 152px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    .bg {
      width: 152px;
      height: 100%;
      background: #6a0203;
      opacity: 0.1;
      position: absolute;
      left: 0px;
      top: 0px;
    }
  }
  .line {
    width: 1px;
    height: 111px;
    background: #2e0000;
  }
}
.tab_top {
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 999;
  height: 55px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #6a0203;
  padding-left: 184px;
  padding-right: 181px;

  .select {
    height: 30px;
    background: #aa0000;
    border-radius: 4px;
    text-align: center;
    padding-left: 14px;
    padding-right: 14px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
    line-height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .normal {
    height: 30px;
    text-align: center;
    padding-left: 14px;
    padding-right: 14px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
    line-height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .m68 {
    margin-right: 68px;
  }
  .m74 {
    margin-right: 74px;
  }
  .m46 {
    margin-right: 46px;
  }
  .m50 {
    margin-right: 50px;
  }
  .m87 {
    margin-right: 87px;
  }
  .m66 {
    margin-right: 66px;
  }
  .m54 {
    margin-right: 54px;
  }
  .m210 {
    margin-right: 210px;
  }
}
.flex {
  flex: 1;
}
.row_center {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.column_l {
  position: relative;
  height: 762px;
  .image_l {
    position: absolute;
    left: 0px;
    top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    .image {
      height: 762px;
    }
    .swiper {
      width: 1364px;
      height: 762px;
    }
    .swiper-box {
      width: 100%;
      height: 762px;
      img {
        height: 762px;
      }
    }
  }
  .sfy_l {
    position: absolute;
    top: 341px;
    left: 0px;
    display: flex;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    transition: all 0.1s;
  }
  .sfy_index {
    z-index: 1;
  }
  .row {
    display: flex;
    flex-direction: row;
    margin-top: 39px;
    .normal {
      width: 334px;
      height: 85px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 13px solid #00eea8;
      border-radius: 52px;
      font-size: 44px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #00eea8;
    }
    .select {
      width: 334px;
      height: 85px;
      display: flex;
      background: #00eea8;
      align-items: center;
      justify-content: center;
      border: 13px solid #00eea8;
      border-radius: 52px;
      font-size: 44px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #000101;
    }
    .m7 {
      margin-left: 7px;
    }
  }
}
.m35 {
  margin-top: 35px;
}
.mk_text {
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #666666;
  line-height: 25px;
  display: flex;
  flex-direction: column;
}
.girl_l {
  position: relative;
  height: 761px;
  .image_l {
    position: absolute;
    left: 0px;
    top: 169px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    .image {
      height: 592px;
    }
  }
  .girl_scroll {
    position: absolute;
    top: 145px;
    left: 0px;
    display: flex;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    transition: all 0.1s;
    .m31 {
      margin-top: 31px;
    }
  }
}
.pda_test_l {
  position: relative;
  height: 749px;
  .image_l {
    position: absolute;
    left: 0px;
    top: 177px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    .image {
      height: 572px;
    }
  }
  .image1 {
    position: absolute;
    top: 146px;
    left: 0px;
    display: flex;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .image2 {
    position: absolute;
    top: 455px;
    left: 0px;
    display: flex;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}
.m21 {
  margin-top: 21px;
}
.m28 {
  margin-top: 28px;
}
.tcp_l {
  position: relative;
  height: 600px;
  margin-top: 106px;
  .image_l {
    position: absolute;
    left: 0px;
    top: 76px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    .image {
      height: 751px;
    }
  }
  .image1 {
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    .image {
      height: 592px;
    }
  }
  .image2 {
    position: absolute;
    top: 424px;
    left: 0px;
    display: flex;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .image3 {
    position: absolute;
    top: 674px;
    left: 0px;
    display: flex;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    .image {
      height: 185px;
    }
  }
}
.xj_sd_l {
  position: relative;
  height: 592px;
  margin-top: 183px;
  .image1 {
    position: absolute;
    left: 0px;
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    .image {
      height: 592px;
    }
  }
  .image2 {
    position: absolute;
    left: 0px;
    bottom: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    .image {
      height: 172px;
    }
  }
}
.xx_l {
  position: relative;
  margin-top: 194px;
  height: 602px;
  width: 100%;
  .image1 {
    position: absolute;
    left: 272px;
    top: 153px;
    display: flex;
    align-items: center;
    justify-content: center;
    .image {
      height: 284px;
    }
  }
  .image2 {
    position: absolute;
    left: 405px;
    top: 245px;
    display: flex;
    align-items: center;
    justify-content: center;
    .image {
      height: 219px;
    }
  }
  .image3 {
    position: absolute;
    left: 556px;
    top: 289px;
    display: flex;
    align-items: center;
    justify-content: center;
    .image {
      height: 236px;
    }
  }
  .image4 {
    position: absolute;
    left: 606px;
    top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    .image {
      height: 235px;
    }
  }
  .image5 {
    position: absolute;
    left: 630px;
    top: 116px;
    display: flex;
    align-items: center;
    justify-content: center;
    .image {
      height: 299px;
    }
  }
  .image6 {
    position: absolute;
    left: 807px;
    top: 133px;
    display: flex;
    align-items: center;
    justify-content: center;
    .image {
      height: 306px;
    }
  }
  .image7 {
    position: absolute;
    left: 941px;
    top: 107px;
    display: flex;
    align-items: center;
    justify-content: center;
    .image {
      height: 388px;
    }
  }
  .image8 {
    position: absolute;
    left: 1100px;
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    .image {
      height: 209px;
    }
  }
  .image9 {
    position: absolute;
    left: 1162px;
    top: 84px;
    display: flex;
    align-items: center;
    justify-content: center;
    .image {
      height: 271px;
    }
  }
  .image10 {
    position: absolute;
    left: 1379px;
    top: 197px;
    display: flex;
    align-items: center;
    justify-content: center;
    .image {
      height: 271px;
    }
  }
}
.m38 {
  margin-top: 38px;
}
.m34 {
  margin-top: 34px;
}
.m200 {
  margin-top: 200px;
}

.l83 {
  margin-left: 83px;
}
.ddz_l {
  width: 870px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 200px;
  .column1 {
    display: flex;
    flex-direction: column;
    position: sticky;
    z-index: 99;
    top: 200px;
    .image {
      width: 351px;
      height: 136px;
    }
    .text {
      width: 418px;
      font-size: 29px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #000000;
      margin-top: 48px;
    }
  }
  .column2 {
    width: 368px;
    display: flex;
    flex-direction: column;
    position: relative;
    .swip_image {
      position: sticky;
      top: 200px;
      width: 366px;
      height: 463px;
    }
    .swip_image1 {
      position: sticky;
      top: 200px;
      width: 366px;
      height: 463px;
    }
  }
}
.m12 {
  margin-top: 12px;
}
.l11 {
  margin-left: 11px;
}
.zh_tab {
  width: 146px;
  height: 47px;
  background: #00eea8;
  border-radius: 24px;
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.zh_lay {
  position: relative;
  margin-top: 24px;
  width: 100%;
  height: 92px;
  .image_l {
    position: absolute;
    top: 20px;
    left: 0px;
    width: 100%;
    display: flex;
    height: 60px;
    width: 100%;
    justify-content: center;
    .image {
      width: 305px;
      height: 60px;
    }
  }
  .tab_l {
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    width: 100%;
    height: 92px;
    .w354 {
      width: 670px;
    }
    .flex {
      flex: 1;
      display: flex;
      justify-content: center;
    }
    .tab {
      width: 260px;
      background: #00eea8;
      border-radius: 50px;
      font-size: 34px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #000000;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.m30 {
  margin-top: 30px;
}
.m160 {
  margin-top: 160px;
}
.m19 {
  margin-top: 19px;
}
.h211 {
  height: 211px;
}
.pic_l {
  width: 100%;
  display: flex;
  overflow: hidden;
  justify-content: left;
}
.mt180 {
  margin-top: 180px;
}
::v-deep.swiper-container {
  .swiper-slide-active {
    .fadeIn {
      animation: fadeIn;
      animation-duration: 2s;
    }

    .bounceInLeft {
      animation: bounceInLeft;
      animation-duration: 2s;
    }
  }
}
</style>